<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container fn text-white mt-2">
      <div>
        <div>
          <span>
            <router-link :to="{ name: 'dashboard-hitler' }">
              <i class="fad fa-angle-double-left fa-2x" />
            </router-link>
          </span>
        </div>
        <div class="p-1 text-center">
          <h3 class="text-dark">
            ประวัติการทำรายการ
          </h3>
        </div>
        <b-row>
          <b-col
            cols="6"
            class="p-0"
            @click="getdeposit"
          >
            <button class="btn1">
              <i class="fad fa-donate" />
              เติมเงิน
            </button>
          </b-col>
          <b-col
            cols="6"
            class="p-0"
            @click="getwithdraw"
          >
            <button class="btn1">
              <i class="fad fa-money-bill-wave" />
              ถอนเงิน
            </button>
          </b-col>
        </b-row>
        <hr>
        <!-- v-for="(key, index) in withdrawData"
        :key="index._id" -->
        <b-row
          v-for="(key, index) in listData"
          :key="index._id"
          class="neon text-center mt-1 mb-1"
        >
          <b-col
            md="4"
            cols="4"
            class="p-0"
          >
            <small> {{ key.amount }} บาท </small>
            <small v-if="key.bonus">, โบนัส {{ key.bonus }} บาท </small>
          </b-col>
          <b-col
            md="4"
            cols="4"
          >
            <small> {{ key.timestamp }}</small><br>
          </b-col>
          <b-col
            md="4"
            cols="4"
            class="text-center"
          >
            <b-badge
              v-if="key.status === 'waiting'"
              variant="light-warning"
            >
              รอดำเนินการ
            </b-badge>
            <b-badge
              v-if="key.status === 'success'"
              variant="light-success"
            >
              สำเร็จ
            </b-badge>
            <!-- <b-badge variant="light-danger">
            ผิดพลาด
          </b-badge> -->
          </b-col>
        </b-row>
        <br><br><br><br>
        <br>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BBadge, BOverlay, BIconController,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      show: true,
      balance: '',
      amount: '',
      listData: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
    }
  },
  mounted() {
    this.getbalance(this.userData.username)
    // this.getwithdraw(this.userData.username)
    this.getUser(this.userData)
  },
  methods: {
    getUser(userData) {
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    getbalance(username) {
      this.show = true
      const params = {
        username,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/balance/getbalance', { params })
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.balance = response.data
        })
        .catch(error => console.log(error))
    },
    getwithdraw() {
      this.show = true
      this.listData = ''
      this.$http
        .get(
          `https://api.ufabangkok.com/api/withdraw/username/${this.userData.username}`,
        )
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.listData = response.data
          let index = 0
          this.listData.forEach(items => {
            this.listData[index].timestamp = moment(items.created_at)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm:ss')
            index += 1
          })
        })
        .catch(error => console.log(error))
    },
    getdeposit() {
      this.show = true
      this.listData = ''
      this.$http
        .get(
          `https://api.ufabangkok.com/api/deposit/username/${this.userData.username}`,
        )
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.listData = response.data
          let index = 0
          this.listData.forEach(items => {
            this.listData[index].timestamp = moment(items.created_at)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm:ss')
            index += 1
          })
        })
        .catch(error => console.log(error))
    },
    submit() {
      const formData = {
        username: this.userData.username,
        amount: this.amount,
        uid: this.userData.userID.id,
        acc_no: this.userData.acc_no,
        bank: this.userData.bank,
      }
      this.$http
        .post('https://api.ufabangkok.com/api/withdraw/store', formData)
        .then(() => {
          this.amount = ''
          this.getbalance(this.userData.username)
          this.getwithdraw(this.userData.username)
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 540px;
}
.btn1 {
  border-radius: 38px;
  width: 100%;
  padding: 10px 25px;
  color: #000 !important;
  border-color: transparent;
  background-color: #ffc800;
}
.balance-cl {
  background-color: #ffc800;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
.neon {
  background-color: #ce0000;
  border-radius: 25px;
  padding: 10px 15px;
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
a {
  color: #fff;
}
/* asdasd */
</style>
